<template>
  <App-necker :title="'展覽列表'" />
  <div class="container">
    <List :service="ExhibService" :fields="fields">
      <template v-slot:item="slot">
        <Card
          :keyword="slot.keyword"
          :link="`/exhib/${slot.row.id}/${slot.row.slug}`"
          :title="slot.row.title"
          :cover="slot.row.banner"
          :author="slot.row.author"
        ></Card>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/pages/index/components/list/Index.vue';
import ExhibService from '@/services/Exhib';
import Card from '@/pages/index/components/list/CardExhib.vue';
import { random } from 'lodash';

export default {
  components: {
    List,
    Card,
  },

  setup() {
    return {
      random,
      ExhibService,
      fields: {
        keyword: null,
        start_ts: null,
        end_ts: null,
        // ...route.query,
      },
    };
  },
};
</script>
