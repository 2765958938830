import { reactive, ref, watch } from 'vue';
import { ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import workService from '@/services/Work';
export default ({ service, url }) => {
  const rows = reactive([]);
  const route = useRoute();
  const router = useRouter();
  let loading = ref(false);

  const routeQuery = reactive({ ...route.query });
  delete routeQuery.page;
  delete routeQuery.limit;

  const update = (params) => {
    if (params.page) {
      pagination.page = params.page;
    }
    router.push({ query: { ...route.query, ...params } });
  };

  const pagination = reactive({
    page: Number(route.query.page) || 1,
    limit: Number(route.query.limit) || 12,
    total: 0,
    update: (page) => {
      update({ page });
      // router.push({ query: { ...route.query, page } });
    },
  });

  const create = (params) => {
    loading.value = true;
    service
      .create({
        ...params,
      })
      .then((res) => {
        rows.unshift(res.data);
        loading.value = false;
      });
  };
  const del = (params) => {
    ElMessageBox.confirm('確定要刪除?', { center: true })
      .then(() => {
        loading.value = true;
        service
          .del({
            ...params,
          })
          .then((res) => {
            const idx = rows.findIndex((x) => {
              return x.id === params.id;
            });
            if (idx > -1) {
              rows.splice(idx, 1);
            }
            loading.value = false;
          });
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const fetcher = (_query) => {
    loading.value = true;
    return service[url || 'list']({
      page: pagination.page,
      limit: pagination.limit,
      ..._query,
    })
      .then((res) => {
        if (res.code !== 1) {
          ElMessageBox.alert('資料庫維護中', { center: true });
          return;
        }
        const _res = res.data ? res.data : res;
        rows.length = 0;
        rows.push(..._res.subset);
        pagination.total = _res.total;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  // 透過網址自動啟動

  watch(
    () => route.query,
    (val) => {
      // if (!val || Object.keys(val).length === 0) return;
      fetcher(val);
    },
    { deep: true, immediate: true }
  );

  return {
    create,
    del,
    fetcher,
    pagination,
    update,
    data: rows,
    loading,
  };
};
