<template>
  <div class="flex flex-col h-full">
    <Filter v-if="!$state.isMobile" :inline="true" class="mb-10" />
    <div v-else>
      <El-Button class="w-full my-8" @click="show = true">搜尋條件</El-Button>
      <Drawer :size="300" v-model:show="show">
        <div class="px-6">
          <Filter :inline="false" />
        </div>
      </Drawer>
    </div>

    <List :rows="rows" v-loading.lock="loading">
      <template v-slot:item="slot">
        <slot name="item" :keyword="query?.keyword" :row="slot.row"></slot>
      </template>
    </List>
    <Pagination></Pagination>
  </div>
</template>

<script>
import { provide, toRefs, ref } from 'vue';

import Drawer from '@/components/Drawer';
import useList from './useListQuery';
import useFilter from './useFilter';
import List from './List.vue';
import Filter from './Filter.vue';
import Pagination from './Pagination.vue';
import { ElButton } from 'element-plus';

export default {
  components: {
    Drawer,
    List,
    Filter,
    Pagination,
    ElButton,
  },
  props: {
    service: {
      type: Function,
    },
    fields: {
      type: Object,
    },
  },
  setup(props) {
    // const route = useRoute();
    const { service, fields } = toRefs(props);
    // 傳入 有哪些條件 和service api

    const { query, opts } = useFilter({
      fields: fields.value,
    });

    const { data, loading, update, pagination } = useList({
      // query: query, //透過filters 產生
      service: service.value,
    });

    provide('filter', {
      query,
      opts,
    });

    provide('list', {
      loading,
      update,
      pagination,
    });
    const show = ref(false);
    return {
      query,
      show,
      rows: data,
      loading,
    };
  },
};
</script>
